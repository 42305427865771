import { defineComponent, inject, provide } from 'vue';
const MenuContextKey = Symbol('menuContextKey');
const useProvideMenu = (props) => {
    provide(MenuContextKey, props);
};
const useInjectMenu = () => {
    return inject(MenuContextKey);
};
const MenuFirstLevelContextKey = Symbol('menuFirstLevelContextKey');
const useProvideFirstLevel = (firstLevel) => {
    provide(MenuFirstLevelContextKey, firstLevel);
};
const useInjectFirstLevel = () => {
    return inject(MenuFirstLevelContextKey, true);
};
const MenuContextProvider = defineComponent({
    name: 'MenuContextProvider',
    inheritAttrs: false,
    props: {
        props: Object,
    },
    setup(props, { slots }) {
        useProvideMenu(Object.assign(Object.assign({}, useInjectMenu()), props.props));
        return () => { var _a; return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots); };
    },
});
export { useProvideMenu, MenuContextKey, useInjectMenu, MenuFirstLevelContextKey, useProvideFirstLevel, useInjectFirstLevel, MenuContextProvider, };
export default useProvideMenu;
