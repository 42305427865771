import { defineComponent, computed, watch, ref } from 'vue';
import PropTypes from '../_util/vue-types';
import classNames from '../_util/classNames';
import warning from '../_util/warning';
import FormItem from './FormItem';
import { getNamePath, containsNamePath } from './utils/valueUtil';
import { defaultValidateMessages } from './utils/messages';
import { allPromiseFinish } from './utils/asyncUtil';
import { toArray } from './utils/typeUtil';
import isEqual from 'lodash-es/isEqual';
import scrollIntoView from 'scroll-into-view-if-needed';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import { tuple } from '../_util/type';
import { useInjectSize } from '../_util/hooks/useSize';
import useConfigInject from '../_util/hooks/useConfigInject';
import { useProvideForm } from './context';
export const formProps = {
    layout: PropTypes.oneOf(tuple('horizontal', 'inline', 'vertical')),
    labelCol: { type: Object },
    wrapperCol: { type: Object },
    colon: PropTypes.looseBool,
    labelAlign: PropTypes.oneOf(tuple('left', 'right')),
    prefixCls: PropTypes.string,
    requiredMark: { type: [String, Boolean], default: undefined },
    /** @deprecated Will warning in future branch. Pls use `requiredMark` instead. */
    hideRequiredMark: PropTypes.looseBool,
    model: PropTypes.object,
    rules: { type: Object },
    validateMessages: PropTypes.object,
    validateOnRuleChange: PropTypes.looseBool,
    // 提交失败自动滚动到第一个错误字段
    scrollToFirstError: { type: [Boolean, Object] },
    onSubmit: PropTypes.func,
    onFinish: PropTypes.func,
    onFinishFailed: PropTypes.func,
    name: PropTypes.string,
    validateTrigger: { type: [String, Array] },
    size: { type: String },
};
function isEqualName(name1, name2) {
    return isEqual(toArray(name1), toArray(name2));
}
const Form = defineComponent({
    name: 'AForm',
    inheritAttrs: false,
    props: initDefaultProps(formProps, {
        layout: 'horizontal',
        hideRequiredMark: false,
        colon: true,
    }),
    Item: FormItem,
    emits: ['finishFailed', 'submit', 'finish'],
    setup(props, { emit, slots, expose, attrs }) {
        const size = useInjectSize(props);
        const { prefixCls, direction, form: contextForm } = useConfigInject('form', props);
        const requiredMark = computed(() => props.requiredMark === '' || props.requiredMark);
        const mergedRequiredMark = computed(() => {
            var _a;
            if (requiredMark.value !== undefined) {
                return requiredMark.value;
            }
            if (contextForm && ((_a = contextForm.value) === null || _a === void 0 ? void 0 : _a.requiredMark) !== undefined) {
                return contextForm.value.requiredMark;
            }
            if (props.hideRequiredMark) {
                return false;
            }
            return true;
        });
        const formClassName = computed(() => classNames(prefixCls.value, {
            [`${prefixCls.value}-${props.layout}`]: true,
            [`${prefixCls.value}-hide-required-mark`]: mergedRequiredMark.value === false,
            [`${prefixCls.value}-rtl`]: direction.value === 'rtl',
            [`${prefixCls.value}-${size.value}`]: size.value,
        }));
        const lastValidatePromise = ref();
        const fields = {};
        const addField = (eventKey, field) => {
            fields[eventKey] = field;
        };
        const removeField = (eventKey) => {
            delete fields[eventKey];
        };
        const getFieldsByNameList = (nameList) => {
            const provideNameList = !!nameList;
            const namePathList = provideNameList ? toArray(nameList).map(getNamePath) : [];
            if (!provideNameList) {
                return Object.values(fields);
            }
            else {
                return Object.values(fields).filter(field => namePathList.findIndex(namePath => isEqualName(namePath, field.fieldName.value)) > -1);
            }
        };
        const resetFields = (name) => {
            if (!props.model) {
                warning(false, 'Form', 'model is required for resetFields to work.');
                return;
            }
            getFieldsByNameList(name).forEach(field => {
                field.resetField();
            });
        };
        const clearValidate = (name) => {
            getFieldsByNameList(name).forEach(field => {
                field.clearValidate();
            });
        };
        const handleFinishFailed = (errorInfo) => {
            const { scrollToFirstError } = props;
            emit('finishFailed', errorInfo);
            if (scrollToFirstError && errorInfo.errorFields.length) {
                let scrollToFieldOptions = {};
                if (typeof scrollToFirstError === 'object') {
                    scrollToFieldOptions = scrollToFirstError;
                }
                scrollToField(errorInfo.errorFields[0].name, scrollToFieldOptions);
            }
        };
        const validate = (...args) => {
            return validateField(...args);
        };
        const scrollToField = (name, options = {}) => {
            const fields = getFieldsByNameList(name);
            if (fields.length) {
                const fieldId = fields[0].fieldId.value;
                const node = fieldId ? document.getElementById(fieldId) : null;
                if (node) {
                    scrollIntoView(node, Object.assign({ scrollMode: 'if-needed', block: 'nearest' }, options));
                }
            }
        };
        // eslint-disable-next-line no-unused-vars
        const getFieldsValue = (nameList = true) => {
            const values = {};
            Object.values(fields).forEach(({ fieldName, fieldValue }) => {
                values[fieldName.value] = fieldValue.value;
            });
            if (nameList === true) {
                return values;
            }
            else {
                const res = {};
                toArray(nameList).forEach(namePath => (res[namePath] = values[namePath]));
                return res;
            }
        };
        const validateFields = (nameList, options) => {
            warning(!(nameList instanceof Function), 'Form', 'validateFields/validateField/validate not support callback, please use promise instead');
            if (!props.model) {
                warning(false, 'Form', 'model is required for validateFields to work.');
                return Promise.reject('Form `model` is required for validateFields to work.');
            }
            const provideNameList = !!nameList;
            const namePathList = provideNameList
                ? toArray(nameList).map(getNamePath)
                : [];
            // Collect result in promise list
            const promiseList = [];
            Object.values(fields).forEach(field => {
                var _a;
                // Add field if not provide `nameList`
                if (!provideNameList) {
                    namePathList.push(field.namePath.value);
                }
                // Skip if without rule
                if (!((_a = field.rules) === null || _a === void 0 ? void 0 : _a.value.length)) {
                    return;
                }
                const fieldNamePath = field.namePath.value;
                // Add field validate rule in to promise list
                if (!provideNameList || containsNamePath(namePathList, fieldNamePath)) {
                    const promise = field.validateRules(Object.assign({ validateMessages: Object.assign(Object.assign({}, defaultValidateMessages), props.validateMessages) }, options));
                    // Wrap promise with field
                    promiseList.push(promise
                        .then(() => ({ name: fieldNamePath, errors: [], warnings: [] }))
                        .catch((ruleErrors) => {
                        const mergedErrors = [];
                        const mergedWarnings = [];
                        ruleErrors.forEach(({ rule: { warningOnly }, errors }) => {
                            if (warningOnly) {
                                mergedWarnings.push(...errors);
                            }
                            else {
                                mergedErrors.push(...errors);
                            }
                        });
                        if (mergedErrors.length) {
                            return Promise.reject({
                                name: fieldNamePath,
                                errors: mergedErrors,
                                warnings: mergedWarnings,
                            });
                        }
                        return {
                            name: fieldNamePath,
                            errors: mergedErrors,
                            warnings: mergedWarnings,
                        };
                    }));
                }
            });
            const summaryPromise = allPromiseFinish(promiseList);
            lastValidatePromise.value = summaryPromise;
            const returnPromise = summaryPromise
                .then(() => {
                if (lastValidatePromise.value === summaryPromise) {
                    return Promise.resolve(getFieldsValue(namePathList));
                }
                return Promise.reject([]);
            })
                .catch(results => {
                const errorList = results.filter(result => result && result.errors.length);
                return Promise.reject({
                    values: getFieldsValue(namePathList),
                    errorFields: errorList,
                    outOfDate: lastValidatePromise.value !== summaryPromise,
                });
            });
            // Do not throw in console
            returnPromise.catch(e => e);
            return returnPromise;
        };
        const validateField = (...args) => {
            return validateFields(...args);
        };
        const handleSubmit = (e) => {
            e.preventDefault();
            e.stopPropagation();
            emit('submit', e);
            const res = validateFields();
            res
                .then(values => {
                emit('finish', values);
            })
                .catch(errors => {
                handleFinishFailed(errors);
            });
        };
        expose({
            resetFields,
            clearValidate,
            validateFields,
            getFieldsValue,
            validate,
            scrollToField,
        });
        useProvideForm({
            model: computed(() => props.model),
            name: computed(() => props.name),
            labelAlign: computed(() => props.labelAlign),
            labelCol: computed(() => props.labelCol),
            wrapperCol: computed(() => props.wrapperCol),
            vertical: computed(() => props.layout === 'vertical'),
            colon: computed(() => props.colon),
            requiredMark: mergedRequiredMark,
            validateTrigger: computed(() => props.validateTrigger),
            rules: computed(() => props.rules),
            addField,
            removeField,
        });
        watch(() => props.rules, () => {
            if (props.validateOnRuleChange) {
                validateFields();
            }
        });
        return () => {
            var _a;
            return (<form {...attrs} onSubmit={handleSubmit} class={[formClassName.value, attrs.class]}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </form>);
        };
    },
});
export default Form;
